import React, { useState } from 'react';
import axios from 'axios';

function TextInput({ onSubmitText, setResponse }) {
  const [textin, setTextin] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!textin.trim()) {
      alert("Please enter some text.");
      return;
    }

    setLoading(true);

    try {
        // Send a POST request to the backend

        // local version
        //const result = await axios.post("http://localhost:8000/openai/", { text: textin });

        //deployed version
        const result = await axios.post("https://2vk4ma03l6.execute-api.us-east-1.amazonaws.com/openai/", { text: textin });
        setResponse(result.data.response);
      } catch (error) {
        console.error("Error calling OpenAI API:", error);
        alert("Failed to fetch response. Check the backend.");
      }

    setLoading(false);


    onSubmitText(textin);
  };

  return (
    <div className="px-10 py-1">
     <textarea
        value={textin}
        onChange={(e) => setTextin(e.target.value)}
        rows="10"
        className="w-full border text-black p-2 mb-4"
        placeholder="Paste your manuscript or text here..."
      ></textarea>
      <button onClick={handleSubmit} className="bg-white text-blue-500 px-6 py-3 rounded shadow hover:bg-gray-100">
        {loading ? "Searching..." : "Find Citations"}
      </button>
    </div>
  );
}

export default TextInput;


