import React, { useState } from 'react';
import TextInput from './components/TextInput';
import CitationCard from './components/CitationCard';
//import Parser from './components/Parser';

 
function App() {
  const [text, setText] = useState("");
  const [response, setResponse] = useState("");

  //example text
  const exampleText = "This is a section from the manuscript that needs a citation.";
  const exampleSuggestions = [
    {
      text: "Example",
      title: 'Example Citation 1 - Smith et al. (2023)',
      details: "Published in the Journal of AI Studies. Discusses foundational aspects of AI."
    },
    {
      text: "Example",
      title: 'Example Citation 2 - Doe et al. (2021)',
      details: "Covers core machine learning concepts and their real-world applications."
    },
    {
      text: "Example",
      title: 'Example Citation 3 - Johnson et al. (2020)',
      details: "Explores the use of AI technologies in modern healthcare."
    }
  ];
  

  return (
    <div className="p-0">



      {/*----HEADER----*/}
      <header className="bg-gray-800 text-white sticky top-0 shadow-md dark:bg-gray-900 dark:text-white">
        <div className="container mx-auto flex justify-between items-center py-4 px-0">
          <h1 className="text-2xl font-bold tracking-wide">CITE<span className="text-2xl font-light tracking-tight">MYWORK</span></h1>
          <nav>
            <ul className="flex space-x-4 items-center">
              <li>
                <a
                  href="https://buymeacoffee.com/akshaydhawan"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-yellow-500 hover:bg-yellow-600 text-black px-4 py-2 rounded flex items-center"
                >
                  <span role="img" aria-label="coffee" className="mr-2">
                    ☕
                  </span>
                  Buy Me a Coffee
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/*----ENTRY----*/}
      <section className="bg-blue-500 text-white text-center py-8  dark:bg-gray-800 dark:text-white">
        <h2 className="text-4xl font-bold mb-4">Cite with Confidence</h2>
        <p className="text-lg mb-6">Easily find and add citations to your work using cutting-edge AI</p>
        <TextInput onSubmitText={setText} setResponse={setResponse} />
      </section>

      {/*----SUGGESTIONS----*/}
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900 p-6">
        <CitationCard text={text ? text : exampleText} highlights={response ? response : exampleSuggestions} />
      </div>


      {/*----FOOTER----*/}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center">
          <p>© 2024 CiteMyWork. All rights reserved.</p>
          {/*<div className="flex justify-center gap-4 mt-4">
            <a href="https://twitter.com" className="hover:text-gray-400">Twitter</a>
            <a href="https://facebook.com" className="hover:text-gray-400">Facebook</a>
            <a href="https://linkedin.com" className="hover:text-gray-400">LinkedIn</a>
          </div>*/}
        </div>
      </footer>

    </div>
  );
}

export default App;
