import React, { useState } from "react";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

const CitationCard = ({ text, highlights }) => {
    const [hoveredCitation, setHoveredCitation] = useState(null);
    const [selectedCitations, setSelectedCitations] = useState(
        Object.fromEntries(highlights.map((_, index) => [index, true])) // All checked by default
      );

    const getHighlightedText = () => {
        let annotatedText = text;

        // Replace highlighted parts with span tags for hover effects
        
        highlights.forEach((highlight, index) => {
            
            if (hoveredCitation === index) {
                
                //define pattern to highlight
                const pattern = new RegExp(RegExp.escape(highlight.text), "g");
                
                //build HTML
                annotatedText = annotatedText.replace(
                    pattern,
                    `<span class="bg-purple-50 underline decoration-purple-500 decoration-2 text-black" data-index="${highlight.index}">${highlight.text}</span>`
                );
                
            }

        });
        return annotatedText;
    };

    // Toggle the checkbox state for a citation
    const toggleCitation = (index) => {
        setSelectedCitations((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle the checkbox
        }));
    };

    // Export citations
    const exportCitations = () => {
        const acceptedCitations = Object.entries(selectedCitations)
            .filter(([_, isSelected]) => isSelected)
            .map(([index]) => highlights[Number(index)]);

        if (acceptedCitations.length === 0) {
            alert("No citations selected to export.");
            return;
            }

        // Create a Word document
        const doc = new Document({
            sections: [
            {
                properties: {},
                children: acceptedCitations.map((citation) =>
                new Paragraph({
                    children: [
                    new TextRun({
                        text: citation.title + '\n\n',
                        bold: true,
                        size: 24, // Font size in half-points (12 points)
                    }),
                    ],
                })
                ),
            },
            ],
        });

        // Generate the Word file
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, "citations.docx");
        });
    };

  return (
    <div className="p-4 border rounded-lg shadow-lg bg-white dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col md:flex-row justify-between">
        {/* Left: Manuscript Text */}
        <div className="flex-[2] pr-4">
            <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-2">
                Entered Text
            </h3>
            {/* Render text with highlights */}
            <div
                className="text-sm text-gray-800 dark:text-gray-400 max-h-80 overflow-y-auto"
                dangerouslySetInnerHTML={{ __html: getHighlightedText() }}
            />
            </div>

        {/* Right: Citation Suggestions */}
        <div className="flex flex-[2] flex-col md:w-1/3 gap-2">
            <h4 className="text-sm font-semibold text-gray-800 dark:text-gray-200 mb-1">
                Citation Suggestions
            </h4>
            <div className="max-h-80 overflow-y-auto p-2 border dark:border-gray-700 bg-gray-50 dark:bg-gray-900 rounded">
                {highlights.map((highlight, index) => (
                    <div
                        key={index}
                        className={`p-3 mb-2 border flex items-center rounded-lg bg-gray-100 dark:bg-gray-700 dark:border-gray-600 ${hoveredCitation === index ? "ring-2 ring-blue-500" : ""}`}
                        onMouseEnter={() => setHoveredCitation(index)}
                        onMouseLeave={() => setHoveredCitation(null)}
                    >

                        {/* Checkbox */}
                        <input
                            type="checkbox"
                            checked={selectedCitations[index] || false}
                            onChange={() => toggleCitation(index)}
                            className="mr-5 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:bg-gray-600 dark:border-gray-500"
                        />

                        <div> 
                            {/*Citation Details*/}
                            <p className="text-sm text-gray-800 dark:text-gray-300 font-medium">
                                {highlight.title}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                {highlight.details}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
      </div>

        {/* Export Button */}
        <div className="grid mt-4 place-content-center">
            <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={exportCitations}
            >
                Export Selected Citations
            </button>
        </div>
    </div>
  );
};


const CitationSuggestion = ({ title, details }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="p-3 border rounded-lg bg-gray-100 dark:bg-gray-700 dark:border-gray-600 transition-all">
      <div className="flex justify-between items-center">
        <p className="text-sm text-gray-800 dark:text-gray-300 font-medium">{title}</p>
        <button
          onClick={() => setExpanded(!expanded)}
          className="text-blue-600 hover:underline dark:text-blue-400"
        >
          {expanded ? "Less" : "More"}
        </button>
      </div>

      {/* Expanded Details */}
      {expanded && (
        <div className="mt-2">
          <p className="text-sm text-gray-600 dark:text-gray-400">{details}</p>
          <div className="mt-2 flex gap-2">
          </div>
        </div>
      )}
    </div>
  );
};

// Escape special characters in RegExp
RegExp.escape = function (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

export default CitationCard;
